<template>
  <div class="l-wrapper">
    <q-scroll-area class="l-grow  bg-grey-2">
      <div class="column q-gutter-lg">
        <q-item class="bg-grey-4">
          <q-item-section>
            <q-item-label class="text-h6 q-ml-md">{{
              data.selCustomer.name
            }}</q-item-label>
            <q-markup-table wrap-cells flat class="bg-transparent">
              <tr>
                <td>Kota</td>
                <td>:</td>
                <td>{{ data.selCustomer.city }}</td>
              </tr>
              <tr>
                <td>Tipe Customer</td>
                <td>:</td>
                <td>{{ data.selCustomer.type }}</td>
              </tr>
              <tr>
                <td>Application</td>
                <td>:</td>
                <td>{{ data.selCustomer.application }}</td>
              </tr>
              <tr>
                <td>Kontak</td>
                <td>:</td>
                <td>{{ data.selCustomer.contact }}</td>
              </tr>
              <tr>
                <td>Kontak Detail</td>
                <td>:</td>
                <td>{{ data.selCustomer.detail }}</td>
              </tr>
            </q-markup-table>
          </q-item-section>
        </q-item>
      </div>
      <div class="q-pa-md">
        <p class="text-center text-bold">Visit History</p>
        <q-btn
          unelevated
          class="full-width q-mb-sm"
          color="grey-8"
          label="Filter"
          @click="
            tempFilter = JSON.parse(JSON.stringify(options));
            showFilter = !showFilter;
          "
        ></q-btn>
      </div>
      <q-list bordered separator class="rounded-borders bg-grey-3">
        <q-item v-for="(val, i) in filteredVisit" :key="i" class="q-px-none">
          <q-expansion-item
            class="full-width"
            :label="`${val.type} : ${moment(val.date).format('DD MMM YY')}`"
            :caption="val.nama_sales"
          >
            <q-card>
              <q-card-section>
                {{ val.summary }}
              </q-card-section>
            </q-card>
          </q-expansion-item>
        </q-item>
      </q-list>
      <div class="q-pa-lg flex flex-center">
        <q-pagination
          v-model="pagination.currentPage"
          :max="pagination.totalPage"
          input
          input-class="text-orange-10"
          @update:model-value="filteringVisit()"
        />
      </div>
    </q-scroll-area>
    <q-dialog v-model="showFilter" position="bottom">
      <q-card style="min-width:300px;">
        <q-card-section class="row items-center justify-between">
          <a class="text-h6">Filter :</a>
          <q-btn flat :ripple="false" icon="close" v-close-popup></q-btn>
        </q-card-section>
        <q-card-section class="column q-gutter-sm">
          <div class="column q-gutter-xs">
            <label class="text-bold"> Tipe Visit :</label>
            <q-select
              outlined
              :options="visit.data.visitType"
              v-model="tempFilter.selTipe"
              multiple
            >
              <template
                v-slot:option="{ itemProps, opt, selected, toggleOption }"
              >
                <q-item v-bind="itemProps">
                  <q-item-section>
                    <q-item-label v-html="opt" />
                  </q-item-section>
                  <q-item-section side>
                    <q-toggle
                      :model-value="selected"
                      @update:model-value="toggleOption(opt)"
                    />
                  </q-item-section>
                </q-item>
              </template>
            </q-select>
          </div>
          <div class="column q-gutter-xs">
            <label class="text-bold"> Tanggal Visit :</label>

            <q-input label="Tanggal" v-model="dateModel" outlined readonly>
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer">
                  <q-popup-proxy
                    ref="qDateProxy"
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <q-date
                      v-model="tempFilter.selDate"
                      range
                      mask="YYYY-MM-DD"
                    >
                      <div class="row items-center justify-between">
                        <q-btn
                          outline
                          color="primary"
                          label="Reset"
                          @click="
                            tempFilter.selDate = {
                              from: null,
                              to: null,
                            }
                          "
                        ></q-btn>
                        <q-btn
                          v-close-popup
                          label="Close"
                          color="primary"
                          flat
                        />
                      </div>
                    </q-date>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
          </div>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn
            flat
            label="Reset"
            color="primary"
            @click="
              options.selTipe = [];
              options.selDate = { from: null, to: null };
              pagination.currentPage = 1;
              filteringVisit();
            "
            v-close-popup
          ></q-btn>
          <q-btn
            unelevated
            label="Apply"
            color="primary"
            @click="
              options.selTipe = tempFilter.selTipe;
              options.selDate = tempFilter.selDate;
              pagination.currentPage = 1;
              filteringVisit();
            "
            v-close-popup
          ></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { computed, reactive, ref, watch } from "vue";
import moment from "moment";
import { onMounted } from "vue";
import useCustomer from "./useCustomer";
import useVisit from "../Visit/useVisit";
export default {
  setup() {
    const { getHistory, data, route, router, pagination, $q } = useCustomer();
    const visit = useVisit();

    onMounted(async () => {
      try {
        $q.loading.show({ message: "Mohon tunggu sebentar" });
        await getHistory(route.params.id);
        await visit.getType();

        if (route.params.filter) {
          let filter = JSON.parse(route.params.filter);
          options.selTipe = filter.selTipe;
          options.selDate = filter.selDate;
        }
        filteringVisit();
        $q.loading.hide();
      } catch (err) {
        $q.loading.hide();
      }
    });

    let dateModel = computed(() => {
      if (tempFilter.value.selDate.from && tempFilter.value.selDate.to) {
        return `${moment(tempFilter.value.selDate.from).format(
          "DD MMM YYYY"
        )} - ${moment(tempFilter.value.selDate.to).format("DD MMM YYYY")}`;
      } else if (typeof tempFilter.value.selDate == "string") {
        return `${moment(tempFilter.value.selDate).format("DD MMM YYYY")}`;
      } else {
        return "-";
      }
    });

    //filter properties
    let showFilter = ref(false);
    let tempFilter = ref({});
    let options = reactive({
      selDate: { from: null, to: null },
      selTipe: [],
    });

    // router replace area
    watch([options], (newValues, prevValues) => {
      let routeFilter = JSON.stringify(newValues[0]);
      router.replace({
        path: `/customer/history/${route.params.id}/${routeFilter}`,
      });
    });

    // filtering area
    let filteredVisit = ref([]);
    let filteringVisit = async () => {
      pagination.filterType = options.selTipe;
      pagination.filterDate = options.selDate;
      await getHistory(route.params.id);
      let visit = JSON.parse(JSON.stringify(data.selCustomer));
      if (visit.visit_list) {
        visit = visit.visit_list;
        // if (pagination.filterType.length > 0) {
        //   pagination.totalPage = 1
        // }
        // if(visit.length == 0){
        //   pagination.totalPage = 1
        // }
        filteredVisit.value = visit;
      } else {
        filteredVisit.value = [];
      }
    };

    return {
      data,
      route,
      moment,
      options,
      showFilter,
      filteredVisit,
      filteringVisit,
      visit,
      tempFilter,
      dateModel,
      pagination,
    };
  },
};
</script>
